<template>
    <v-list>
        <v-list-item v-for="(client,i) in clients" :key="i" link @click="goToExamDetail(client.id)">
            <v-list-item-content>
                <v-list-item-title v-text="client.name"/>
            </v-list-item-content>

            <v-list-item-icon>
                <v-chip color="ternary" class="px-4">
                    <v-icon color="secondary" v-text="'$exams'"/>
                </v-chip>
            </v-list-item-icon>
        </v-list-item>
    </v-list>
</template>

<script>
export default {
    name: "ClientExams",
    props: {
        clients: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        route(vm = this) {
            return `${vm.accountType}.exams.details`
        }
    },
    methods: {
        goToExamDetail(id) {
            const vm = this
            vm.goTo({name: vm.route, params: {id}})
        },
    }
}
</script>

<style scoped>

</style>