import { render, staticRenderFns } from "./ClientExams.vue?vue&type=template&id=0b6c93d8&scoped=true&"
import script from "./ClientExams.vue?vue&type=script&lang=js&"
export * from "./ClientExams.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b6c93d8",
  null
  
)

export default component.exports