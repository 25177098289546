<template>
    <master-panel>
        <template v-slot:body>
            <v-row>
                <v-col cols="12" class="title" v-if="clients.length > 0">
                    Estimad@ <b>{{ name }}</b>, <b>{{ doctor }}</b>
                    solicita confirmar la consulta de los siguientes pacientes:
                </v-col>
                <v-col cols="12" v-if="loading">
                    <loading-spinner line height=""/>
                </v-col>
                <v-col cols="12" v-else>
                    <client-exams :clients="clients"/>
                </v-col>
                <v-col cols="12" class="text-center" v-if="clients.length === 0">
                    <small>{{ loading ? 'Cargando...' : 'Sin resultados' }}</small>
                </v-col>
            </v-row>
        </template>
        <template v-slot:buttons>
            <v-btn color="primary" class="normal-btn" small depressed @click="goHome">
                Dashboard
            </v-btn>
        </template>
    </master-panel>
</template>

<script>

import ClientExams from "@/components/lists/ClientExams";
import MasterPanel from "@/components/layouts/MasterPanel";
import LoadingSpinner from "@/components/common/LoadingSpinner";
import httpService from "@/services/httpService";

export default {
    name: "Exams",
    components: {LoadingSpinner, MasterPanel, ClientExams},
    data() {
        return {
            loading: false,
            clients: []
        }
    },
    computed: {
        name(vm = this) {
            return vm.currentUser?.name || ''
        },
        doctor(vm = this) {
            return vm.currentUser?.doctor?.name || ''
        },
        doctorId(vm = this) {
            return vm.currentUser?.doctor_id || null
        }
    },
    async mounted() {
        const vm = this
        await vm.loadClients()
    },
    methods: {
        async loadClients() {
            const vm = this
            vm.loading = true

            let response = await httpService.get(`doctor/${vm.doctorId}/clients`)
            // console.log('response', response)
            vm.clients = response.data.clients

            vm.loading = false
        },
    }
}
</script>

<style scoped>

.title {
    text-align: center;
    font-size: 17px !important;
    line-height: 21px !important;
}

</style>
